import styled from '@emotion/styled';
import ContainedButton from 'common/ContainedButton';
import Typography from 'components/shared/Typography';
import Tooltip from 'components/Tooltip';
import commonStyles from 'utils/common-styles';
import { pageSize } from 'utils/devices';
import css from '@emotion/css';

const ListViewCss = css`
  .job-image-wrapper {
    height: 0;
    .job-image {
      height: 0;
    }
    .job-company-info {
      top: 24px;
      height: 80px;
      .job-company-img {
        width: 80px;
        height: 80px;
      }
    }
  }
  .job-info {
    padding: 24px 24px 32px 128px;
    .job-title {
      height: auto;
      margin-bottom: 15px;
      padding-right: 24px;
    }
    .job-status {
      margin-top: 16px;
      padding-top: 16px;
    }
    .job-status.stuffed {
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 18px;
      padding-top: 24px;
    }
  }
`;

export const StyledJobCard = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;
  background: #111112;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  @media ${pageSize.S}, ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    ${({ isListView }) => isListView && ListViewCss}
  }
`;
export const JobImageWrapper = styled.div`
  position: relative;
  height: 160px;
  @media ${pageSize.M}, ${pageSize.S} {
    height: 132px;
  }
  @media ${pageSize.XS} {
    height: 0;
  }
`;
export const JobImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  @media ${pageSize.M}, ${pageSize.S} {
    height: 132px;
  }
  @media ${pageSize.XS} {
    height: 0;
  }
`;
export const JobImageGradient = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(358.48deg, #000000 -4.2%, rgba(0, 0, 0, 0) 40.97%);
`;
export const JobCompanyContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-bottom: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  @media ${pageSize.XS} {
    bottom: -24px;
    padding-bottom: 0;
    transform: translateY(100%);
  }
`;
export const JobButtonWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  gap: 6px;
  @media ${pageSize.XS} {
    top: 24px;
    right: 16px;
  }
`;
export const JobCompanyImage = styled.img`
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
`;
export const JobCompanyName = styled(Typography)`
  ${commonStyles.ui_heading_3}
  margin-left: 16px;
  color: rgba(242, 244, 255, 0.9);
  @media ${pageSize.XS} {
    display: none;
  }
`;
export const JobInfoWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 32px 24px;
  height: 100%;
  @media ${pageSize.XS} {
    padding-top: 88px;
  }
`;
export const JobTitle = styled(Typography)`
  ${commonStyles.ui_heading_2}
  height: 64px;
  margin: 0 0 52px;
  @media ${pageSize.XS} {
    margin: 0 0 16px;
  }
`;
export const JobTagListWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  gap: 4px;
`;
export const JobTagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  ${({ mb }) => mb && `margin-bottom: ${mb}px;`}
`;
export const JobStatus = styled.div`
  display: flex;
  justify-content: ${({ justifyEnd }) => (justifyEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  margin-top: ${({ mt }) => mt ?? '34'}px;
  padding-top: ${({ pt }) => pt ?? '18'}px;
  border-top: 2px solid rgba(204, 213, 255, 0.11);
  @media ${pageSize.XS} {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    & > p {
      flex-shrink: 0;
    }
  }
  &.stuffed {
    flex-flow: column;
    align-items: flex-start;
    gap: 16px;
  }
  &.applicants {
    justify-content: flex-start;
    gap: 16px;
  }
`;
export const JobStatusTopology = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  ${commonStyles.ui_text_medium}
  ${({ color }) => color && `color: ${color};`}
  font-size: ${({ fontSize }) => fontSize ?? 'auto'};
  line-height: ${({ lineHeight }) => lineHeight ?? 'auto'};
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}; padding: 2px 6px;`}
`;
export const StyledTooltip = styled(Tooltip)`
  .__react_component_tooltip.show {
    border: 1px solid rgba(204, 213, 255, 0.22) !important;
  }
`;
export const StyledButton = styled(ContainedButton)`
  @media ${pageSize.XS} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
