import Loader from 'common/Loader';
import useRecommendedJobs from 'hooks/useRecommendedJobs';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { profileSelectors } from 'store/profile';
import JobListSearch from '../commons/JobListSearch';
import { isEmpty } from 'ramda';
import SearchEmptyState from 'components/SearchEmptyState';

const RecommendedJobs = ({ query, sortBy }) => {
  const profileId = useSelector(profileSelectors.selectMyProfileId);
  const isCompany = useSelector(profileSelectors.selectIsMyProfileCompany);

  const { getRecommendedJobs, hasMoreRecommendedJobs, recommendedJobs, recommendedJobsPage, loadingRecommendedJobs } =
    useRecommendedJobs(sortBy);

  useEffect(() => {
    getRecommendedJobs(query, profileId, isCompany, 1, 4, sortBy);
  }, [query]);

  return (
    <InfiniteScroll
      loadMore={() => getRecommendedJobs(query, profileId, isCompany, recommendedJobsPage, 4, sortBy)}
      hasMore={hasMoreRecommendedJobs}
      loader={<Loader key={0} style={{ marginTop: '43px' }} />}
      useWindow
    >
      <JobListSearch jobs={recommendedJobs} hideApply />
      {!loadingRecommendedJobs && isEmpty(recommendedJobs) && (
        <SearchEmptyState emptyStateFor="jobs" url={`${process.env.REACT_APP_NEXT_BASE_URL}/search/jobs`} />
      )}
    </InfiniteScroll>
  );
};

export default RecommendedJobs;
