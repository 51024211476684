import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Typography from 'components/shared/Typography';
import FormSwitcher from 'common/form/FormSwitcher';
import { pageSize } from 'utils/devices';
import ContainedButton from 'common/ContainedButton';
import useViewport from 'hooks/useViewport';
import DisplaySwitcher from 'common/DisplaySwitcher';
import useJobServices from 'hooks/api/useJobServices';
import { profileSelectors } from 'store/profile';
import { useSelector } from 'react-redux';
import { jobsSelectors } from 'store/jobs';
import FormSelect from 'common/form/FormSelect';
import { getFormSelectTypeArray } from 'utils/tagHelpers';
import { tagSelectors } from 'store/tags';
import useTagService from 'hooks/api/useTagService';
import { compose, filter, flip, includes, prop } from 'ramda';

const MainWrapper = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const Divider = styled.div`
  height: 1px;
  background: rgba(204, 212, 255, 0.11);
`;

const BottomWrapper = styled.div`
  display: flex;
  height: auto;
  padding: 0px 0px 15px;
  align-items: flex-start;
  gap: 12px;

  @media ${pageSize.M}, ${pageSize.L}, ${pageSize.XL} {
    width: 50%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  background: rgba(17, 17, 18, 1);
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  @media ${pageSize.XS}, ${pageSize.S}, ${pageSize.M} {
    flex-flow: column;
  }
  @media ${pageSize.XS} {
    padding: 16px;
  }
`;

const StyledFormSwitcher = styled(FormSwitcher)`
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  display: flex;
  padding: 2px;
  button.switcher-button {
    padding: 8px 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  @media ${pageSize.XS} {
    gap: 16px;
    flex-grow: 1;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  @media ${pageSize.XS} {
    gap: 16px;
    flex-grow: 1;
  }
  @media ${pageSize.S}, ${pageSize.M} {
    justify-content: space-between;
  }
`;

const postedStatusFields = [
  {
    name: 'Unstaffed',
    value: 'unstaffed'
  },
  {
    name: 'Staffed',
    value: 'staffed'
  },
  {
    name: 'Closed',
    value: 'closed'
  }
];

const FilterButton = styled(ContainedButton)`
  button.filter-btn {
    height: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
  overflow: auto;
`;

export const Spacer = styled.div`
  height: 0px;
  margin: 16px 0;
  border: 1px solid rgba(204, 213, 255, 0.11);
`;

const disciplinesFormat = data => list => filter(compose(flip(includes)(data), prop('label')), list);

const JobsHeader = ({ disciplines = [], header, recommendedUrl, setStatus, setQuery, status, ...rest }) => {
  const { isXS } = useViewport();
  const forAllJobs = header === 'all';
  const forFavoriteJobs = header === 'favorite';
  const forJobsIPosted = header === 'posted-jobs';
  const { getUnreadJobsCounters } = useJobServices();
  const profile = useSelector(profileSelectors.selectActiveProfileId);
  const counters = useSelector(jobsSelectors.selectUnreadCounters);

  const [disciplinesValues, setDisciplinesValues] = useState();

  const { getTags } = useTagService();

  const tagDisciplines = useSelector(tagSelectors.selectTagsByType('disciplines'));
  const disciplinesOptions = getFormSelectTypeArray({ arr: tagDisciplines.data });

  const myJobsOptions = useMemo(
    () => [
      {
        name: 'Saved',
        value: 'saved',
        indicator: counters?.savedCount === 0 ? null : counters?.savedCount
      },
      {
        name: 'Applied',
        value: 'applied',
        indicator: counters?.appliedCount === 0 ? null : counters?.appliedCount
      },
      {
        name: 'Offers', // ONLY affects the label in FE tab. The value is still 'selected'
        value: 'selected',
        indicator: counters?.selectedCount === 0 ? null : counters?.selectedCount
      },
      {
        name: 'Hired',
        value: 'hired',
        indicator: counters?.hiredCount === 0 ? null : counters?.hiredCount
      }
    ],
    [counters]
  );

  useEffect(() => {
    (async () => {
      await getTags('discipline');
    })();
  }, []);

  useEffect(() => {
    const disciplinesFormated = disciplinesFormat(disciplines)(disciplinesOptions);
    setDisciplinesValues(disciplinesFormated);
  }, [tagDisciplines]);

  useEffect(() => {
    getUnreadJobsCounters(profile);
  }, []);

  const onChangeDisciplines = disciplines => {
    setDisciplinesValues(disciplines);
    setQuery(disciplines);
  };

  return (
    <Header {...rest}>
      <MainWrapper>
        <TopWrapper>
          {(forFavoriteJobs || forJobsIPosted) && (
            <Flex>
              <StyledFormSwitcher
                backgroundColor="transparent"
                initialValue={status}
                options={forFavoriteJobs ? myJobsOptions : postedStatusFields}
                onChange={setStatus}
              />
            </Flex>
          )}
          {forAllJobs && (
            <div>
              <Typography variant="h3">Recommended for you</Typography>
              <Typography variant="small" color="rgba(230, 234, 255, 0.35)">
                Based on your profile and search history
              </Typography>
            </div>
          )}

          <ButtonContainer>
            {!isXS && forAllJobs && (
              <FilterButton
                className="filter-btn"
                icon="filter"
                backgroundColor="rgba(204, 213, 255, 0.11)"
                onClick={() => (window.location.href = recommendedUrl)}
              >
                Advanced Search
              </FilterButton>
            )}

            <ButtonWrapper>{!isXS && <DisplaySwitcher />}</ButtonWrapper>
          </ButtonContainer>

          {/* <Spacer /> */}
        </TopWrapper>

        {forAllJobs && (
          <>
            <Divider />
            <BottomWrapper>
              <FormSelect
                width={'100%'}
                value={disciplinesValues}
                options={disciplinesOptions}
                isMulti
                maxOptions={3}
                onChange={disciplines => onChangeDisciplines(disciplines)}
                placeholder={'Discipline'}
                data-testid="disciplines-selector"
              />
            </BottomWrapper>
          </>
        )}
      </MainWrapper>
    </Header>
  );
};

export default JobsHeader;
