import jobService from 'api/jobService';
import React from 'react';

const useRecommendedJobs = sortBy => {
  const [jobs, setJobs] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const getRecommendedJobs = React.useCallback(
    (query, profileId, isCompany, page = 1, limit = 4) => {
      if (!isLoading && profileId !== undefined) {
        setIsLoading(true);

        const promise = jobService.getJobsFromService(page, limit, query);

        return promise
          .then(res => {
            const { data } = res.data;
            const { total } = res.data.pagination;

            if (page === 1) {
              setPage(1);
              setJobs([...data]);
            } else {
              setJobs([...jobs, ...data]);
            }
            setHasMore(jobs.length + limit < total);
            setPage(page + 1);
            setIsLoading(false);
          })
          .catch(() => {
            setHasMore(false);
            setIsLoading(false);
          });
      }
    },
    [isLoading, hasMore, page]
  );

  React.useEffect(() => {
    setPage(1);
    setJobs([]);
  }, [sortBy]);

  return {
    recommendedJobs: jobs,
    getRecommendedJobs,
    loadingRecommendedJobs: isLoading,
    hasMoreRecommendedJobs: hasMore,
    recommendedJobsPage: page
  };
};

export default useRecommendedJobs;
